import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/6to8_independence/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/6to8_independence/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/6to8_independence/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/6to8_independence/4.jpg';
import p5 from 'assests/Photos/SchoolProgram/2023/6to8_independence/5.jpg';
import p6 from 'assests/Photos/SchoolProgram/2023/6to8_independence/6.jpg';
import p7 from 'assests/Photos/SchoolProgram/2023/6to8_independence/7.jpg';
import p8 from 'assests/Photos/SchoolProgram/2023/6to8_independence/8.jpg';
import p9 from 'assests/Photos/SchoolProgram/2023/6to8_independence/9.jpg';
import p10 from 'assests/Photos/SchoolProgram/2023/6to8_independence/10.jpg';
import p11 from 'assests/Photos/SchoolProgram/2023/6to8_independence/11.jpg';
import p12 from 'assests/Photos/SchoolProgram/2023/6to8_independence/12.jpg';
import p13 from 'assests/Photos/SchoolProgram/2023/6to8_independence/13.jpg';
import p14 from 'assests/Photos/SchoolProgram/2023/6to8_independence/14.jpg';
import p15 from 'assests/Photos/SchoolProgram/2023/6to8_independence/15.jpg';
import p16 from 'assests/Photos/SchoolProgram/2023/6to8_independence/16.jpg';
import p17 from 'assests/Photos/SchoolProgram/2023/6to8_independence/17.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { SidebarArticles } from 'views/SchoolProgram/components';
import Sidebar2023 from './Sidebar2023/Sidebar2023';
import Archive2022 from './Archive2022';


const Independence2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 2,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },

        {
            src: p7,
            source: p7,
            rows: 2,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 2,
            cols: 1,
        },
        
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },       
        {
            src: p17,
            source: p17,
            rows: 1,
            cols: 2,
        },
       
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                INDEPENDENCE DAY
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6-8  Date: 15 AUGUST 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School, Yeshawanthpur celebrated 77th Independence Day on 15th August, 2023 with great gusto and patriotic fervour The programme commenced with the unfurling of the tricolour flag followed by the National Anthem. The School Principal Ms Sheeja Manoj greeted the students and the staff members on this momentous occasion. She encouraged the students to become conscientious citizens of the country and work towards its progress.
                                    <br></br>
                                    The students participated in an array of events. The senior students marched in perfect unison as a mark of respect towards the nation. The mesmerising dance-drama on the theme ‘Empowering communities, Transforming the World’’ was presented by the students of classes 6-8 under the aegis of Ek Bharat Shresth Bharat Association. Their vibrant folk dances and colourful costumes highlighted the rich cultural and traditional ethos of Karnataka and Ladakh. It was followed by the soulful rendition of patriotic songs by the school choir. The celebration filled the air with pride and patriotism.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Freedom in the mind, faith in the words, pride in our hearts and memories in our souls, let us salute our Nation on Independence Day”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 

                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Independence2023;