/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import p1 from 'assests/Photos/SchoolProgram/goonj/1.png'
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Content = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h4' align='center'>
        GOONJ 2022
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        National Public School, Yeshwanthpur proudly hosted the fourth edition of their Inter-School Fest GOONJ on 22 July 2022, with an array of events. This mega event commenced with 355+ enthusiastic participants from over nineteen schools in and around Bangalore.
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Eight departments organised Eleven competitions - Debattle, Aarush (Nukkad Natak), Kshitij (Folk Dance), Radical (Math Walk), Space-Bar (Code-Ex), Synergy (Panel Discussion), Delta (Treasure Hunt & Fun, Fitness & Facts), and Exodia (Impeditio, Carpe Vitae, Caveat Emptor). Goonj 2022 witnessed the convergence of the brightest minds on a common platform. While Debattle brought out the linguistic and debating skills to the fore, Aarush enthralled the audience with each team conveying a social message, Synergy impugned the contemporary issues faced by youngsters, Delta assessed the fitness and scientific temper,while Radical helped the contestants hone their mathematical knowledge with fashion. Coders competed keenly to take home the laurels in Space-Bar and Exodia saw the budding entrepreneurs highlight the strengths of their business in Crisis Management, Job Recruitment and Stock Exchange.
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The events were conducted simultaneously with intellectual sparring that brought out the best in everyone, leaving the ambience charged with excitement and anticipation of the participants, judges and the audience. The spirit of competition permeated the air, only to be punctuated with many a thunderous applause. While the winners and runners up got their due acknowledgement and rewards, the other contestants went back with commendations and stimulated minds.
        </Typography>

        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'200%'}
            width={'100%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '200%',
              maxHeight: 1000,
            }}
          />
        </Box>
        <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
          “IT’S NOT ABOUT BEING THE BEST, IT’S ABOUT BEING BETTER THAN YOU WERE YESTERDAY.”
        </Typography>
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
